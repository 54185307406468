export enum E_ResourceType {
  AcquisitionSource = "AcquisitionSource",
  AddressAutocomplete = "AddressAutocomplete",
  AddressSuggestion = "AddressSuggestion",
  AllowedPaymentProvider = "AllowedPaymentProvider",
  Appointment = "Appointment",
  AppointmentCancellationReason = "AppointmentCancellationReason",
  AppointmentReason = "AppointmentReason",
  AppointmentSettings = "AppointmentSettings",
  AppointmentsStatistics = "AppointmentsStatistics",
  AppointmentType = "AppointmentType",
  AppointmentTypeCategory = "AppointmentTypeCategory",
  AppointmentTypeDescription = "AppointmentTypeDescription",
  AppointmentTypeDescriptionList = "AppointmentTypeDescriptionList",
  AvailableAppointment = "AvailableAppointment",
  Availability = "Availability",
  AvailabilityGuide = "AvailabilityGuide",
  BookableAppointment = "BookableAppointment",
  Brand = "Brand",
  BrandCustomDomain = "BrandCustomDomain",
  BrandDefaultDomain = "BrandDefaultDomain",
  BrandInfo = "BrandInfo",
  BulkData = "BulkData",
  CachedAppointment = "CachedAppointment",
  CancellationStatistics = "CancellationStatistics",
  Chat = "Chat",
  CustomDomain = "CustomDomain",
  DefaultDomain = "DefaultDomain",
  DefaultDomainRegion = "DefaultDomainRegion",
  DentallyApiRateLimit = "DentallyApiRateLimit",
  Device = "Device",
  Domain = "Domain", // Not a real resource type but required for table schemas
  DomainSiteHistory = "DomainSiteHistory", // Not a real resource type but required for table schemas
  DevLink = "DevLink",
  PatientEstimate = "PatientEstimate",
  Features = "Features",
  FeatureFlag = "FeatureFlag",
  GlobalConsultationType = "GlobalConsultationType",
  GpPractice = "GpPractice",
  History = "History",
  Invoice = "Invoice",
  LoggedInUser = "LoggedInUser",
  LoggedInUserSettings = "LoggedInUserSettings",
  LogTest = "LogTest", //Dev/sparkle mode mutation to write a log to NR
  MedicalHistoryQuestions = "MedicalHistoryQuestions",
  OnlineSigningStatistics = "OnlineSigningStatistics",
  PaginatedAppointmentList = "PaginatedAppointmentList",
  Patient = "Patient",
  PatientAction = "PatientAction",
  PatientCheckIn = "PatientCheckIn",
  PatientDetails = "PatientDetails", // NOTE: Not a real resource type, just used to allow access for patients only
  PatientDetailsVerification = "PatientDetailsVerification", // NOTE: Not a real resource type, just used to allow access for patients only
  PatientList = "PatientList",
  PatientLoginFlow = "PatientLoginFlow",
  PatientImpersonation = "PatientImpersonation",
  PatientMedicalHistory = "PatientMedicalHistory",
  PatientNhsPr = "PatientNhsPr",
  PatientPayment = "PatientPayment",
  PatientRecallAppointmentLink = "PatientRecallAppointmentLink",
  PatientShortCode = "PatientShortCode",
  PatientUnsubscribe = "PatientUnsubscribe",
  PaymentPlan = "PaymentPlan",
  PaymentPlanAllowedSite = "PaymentPlanAllowedSite",
  PaymentProvider = "PaymentProvider",
  PaymentProviderCategoryDetails = "PaymentProviderCategoryDetails",
  PaymentProviderPaymentPlan = "PaymentProviderPaymentPlan",
  PipAuthorize = "PipAuthorize",
  Practice = "Practice",
  PracticeList = "PracticeList", // NOTE: Not a real resource type, just used to block for all but L5 users
  PracticeAppointmentTypeCategories = "PracticeAppointmentTypeCategories",
  PracticeConsultationType = "PracticeConsultationType",
  PracticeSettings = "PracticeSettings",
  Practitioner = "Practitioner",
  RawAvailability = "RawAvailability", // Required for internal permissions
  RecurringAppointment = "RecurringAppointment",
  RecurringAppointmentPractitioner = "RecurringAppointmentPractitioner",
  Site = "Site",
  SiteAllowedAppointmentType = "SiteAllowedAppointmentType",
  SiteAppointmentType = "SiteAppointmentType",
  SiteAppointmentTypePractitioner = "SiteAppointmentTypePractitioner",
  SiteAppointmentTypeSession = "SiteAppointmentTypeSession",
  SiteDefaultPaymentPlan = "SiteDefaultPaymentPlan",
  SiteExternalPaymentProvider = "SiteExternalPaymentProvider",
  SitePaymentPlanCategory = "SitePaymentPlanCategory",
  SitePaymentPlan = "SitePaymentPlan",
  SitePaymentPlanAllowance = "SitePaymentPlanAllowance",
  SitePaymentPlanDiscount = "SitePaymentPlanDiscount",
  SiteSettings = "SiteSettings",
  SiteSettings_OnlineSigning = "SiteSettings_OnlineSigning", // Required for L3 configurable permissions
  SiteSettings_PatientNotifications = "SiteSettings_PatientNotifications", // Required for L3 configurable permissions
  SiteSettings_PaymentTypes = "SiteSettings_PaymentTypes", // Required for L3 configurable permissions
  SiteSettings_PracticeNotifications = "SiteSettings_PracticeNotifications", // Required for L3 configurable permissions
  SiteSettings_TaskReminderNotifications = "SiteSettings_TaskReminderNotifications", // Required for L3 configurable permissions
  Statistics = "Statistics",
  StripeCheckout = "StripeCheckout",
  SupportQuery = "SupportQuery",
  TaskReminderNotification = "TaskReminderNotification",
  UrlSiteAlias = "UrlSiteAlias",
  User = "User",
  UserAllowedSite = "UserAllowedSite",
  Verification = "Verification",
}

export type FieldType = "TEXT" | "int" | "timestamp" | "uuid" | "numeric" | "int[]" | "BOOLEAN" | "character varying(15)" | "char(7)" | "character(7)";

export class FieldDefinition {
  constructor(
    public name: string,
    public type: FieldType,
    public canBeNull: boolean = true,
    public defaultValue: string | number = "",
    public isUnique: boolean = false
  ) {}
}

export class ForeignKeyDefinition {
  constructor(
    public id: number,
    public sourceFields: Array<string>,
    public targetType: E_ResourceType,
    public targetFields: Array<string>,
    public cascade: boolean
  ) {}
}

export interface I_Schema {
  resourceType: E_ResourceType;
  allProperties: Array<FieldDefinition>;
  foreignKeys: Array<ForeignKeyDefinition>;
  primaryKeys: Array<string>;
  mandatoryProperties: Array<string>;
  typeCastProperties: Map<string, string>;
  createTableSql(tablePrefix: string): string;
  createAuditTableSql(tablePrefix: string): string;
  foreignKeySql(tablePrefix: string): string;
  dropTableSql(tablePrefix: string, typename: string): string;
  insertIntoSql(tablePrefix: string): string;
  generateAssignment(field: FieldDefinition): string;
}

export abstract class Schema {
  private static _castRequiredTypes = new Array<string>("uuid", "timestamp", "int[]", "numeric");

  protected static _allProperties(fields: Array<FieldDefinition>): Array<FieldDefinition> {
    return fields;
  }

  protected static _mandatoryProperties(fields: Array<FieldDefinition>): Array<string> {
    return fields.filter((f) => !f.canBeNull && (f.defaultValue === null || f.defaultValue === undefined)).map((f) => f.name);
  }

  protected static _typeCastProperties(fields: Array<FieldDefinition>): Map<string, string> {
    const response = new Map<string, string>();
    const castFields = fields.filter((f) => Schema._castRequiredTypes.includes(f.type));
    for (const field of castFields) {
      response.set(field.name, field.type);
    }
    return response;
  }

  private static _fieldsSql(fields: Array<FieldDefinition>): string {
    return fields.map((f) => `"${f.name}" ${f.type}${!f.canBeNull ? " NOT NULL" : ""}${f.defaultValue ? ` DEFAULT ${f.defaultValue}` : ""}`).join(",\n");
  }

  protected static _createTableSql(tablePrefix: string, typeName: string, fields: Array<FieldDefinition>, primaryKeys: Array<string>): string {
    let pkConstraint = "";
    if (primaryKeys && primaryKeys.length > 0) {
      pkConstraint = `, CONSTRAINT "${tablePrefix}_${typeName}_pk" PRIMARY KEY (${primaryKeys.map((pk) => `"${pk}"`).join(",")})`;
    }

    const fldConstraints = new Array<string>();
    for (const field of fields) {
      if (field.isUnique) fldConstraints.push(`, CONSTRAINT "${tablePrefix}_${typeName}_${field.name}_uv" UNIQUE ("${field.name}")`);
    }
    const fldConstraint = fldConstraints.join("");

    return `
    CREATE TABLE "${tablePrefix}_${typeName}" (
      ${Schema._fieldsSql(fields)}${pkConstraint}${fldConstraint}
    ) WITH (
      OIDS=FALSE
    );
    `;
  }

  protected static _createAuditTableSql(tablePrefix: string, typeName: string, primaryKeys: Array<string>): string {
    let pkConstraint = "";
    if (primaryKeys && primaryKeys.length > 0) {
      const auditKeys = [...primaryKeys, "audit_timestamp"];
      pkConstraint = `, CONSTRAINT "${tablePrefix}_${typeName}Audit_pk" PRIMARY KEY (${auditKeys.map((pk) => `"${pk}"`).join(",")})`;
    }

    return `
    CREATE TABLE "${tablePrefix}_${typeName}Audit" (
      LIKE "${tablePrefix}_${typeName}",
      "audit_id" uuid NOT NULL DEFAULT uuid_generate_v4(),
      "audit_action" TEXT NOT NULL,
      "audit_timestamp" TIMESTAMP NOT NULL,
      "audit_author" TEXT NOT NULL,
      "audit_impersonator" TEXT${pkConstraint}
    ) WITH (
      OIDS=FALSE
    );
    `;
  }

  private static _fkConstraintSql(tablePrefix: string, typeName: string, foreignKeys: Array<ForeignKeyDefinition>): string {
    const constraints = new Array<string>();
    for (const fk of foreignKeys) {
      const sourceFields = fk.sourceFields.map((sf) => `"${sf}"`).join(",");
      const targetFields = fk.targetFields.map((tf) => `"${tf}"`).join(",");
      constraints.push(
        `ADD CONSTRAINT "${tablePrefix}_${typeName}_fk${fk.id}" FOREIGN KEY (${sourceFields}) REFERENCES "${tablePrefix}_${fk.targetType}"(${targetFields}) ${
          fk.cascade ? "ON DELETE CASCADE" : ""
        }`
      );
    }
    return constraints.join(",\n");
  }

  protected static _foreignKeySql(tablePrefix: string, typeName: string, foreignKeys: Array<ForeignKeyDefinition>): string {
    if (foreignKeys.length === 0) return "";

    return `
    ALTER TABLE "${tablePrefix}_${typeName}"
    ${Schema._fkConstraintSql(tablePrefix, typeName, foreignKeys)};
    `;
  }

  protected static _insertIntoTableSql(tablePrefix: string, typeName: string, fields: Array<FieldDefinition>): string {
    const lines = new Array<string>(
      `INSERT INTO "${tablePrefix}_${typeName}" (`,
      fields.map((f) => f.name).join(", "),
      `) VALUES (`,
      fields.map((f) => Schema._generateCastValue(f)).join(", "),
      `)`
    );
    return lines.join(" ");
  }

  private static _processSchemaArg(schema: I_Schema | string): { typeName: string; includeAudit: boolean } {
    if (typeof schema === "string" || schema instanceof String) {
      return { typeName: schema.toString(), includeAudit: false };
    } else {
      return { typeName: schema.resourceType, includeAudit: schema.createAuditTableSql("test") !== "" };
    }
  }

  public static cloneTableSql(tablePrefix: string, clonePrefix: string, schema: I_Schema | string): string {
    const { typeName, includeAudit } = this._processSchemaArg(schema);
    const table = `CREATE TABLE "${clonePrefix}_${typeName}" AS TABLE "${tablePrefix}_${typeName}";`;
    if (!includeAudit) return table;
    const audit = `CREATE TABLE "${clonePrefix}_${typeName}Audit" AS TABLE "${tablePrefix}_${typeName}Audit";`;
    return `${table}${"\n"}${audit}`;
  }

  public static copyRowsSql(fromTablePrefix: string, toTablePrefix: string, schema: I_Schema | string): string {
    const { typeName, includeAudit } = this._processSchemaArg(schema);
    const table = `INSERT INTO "${toTablePrefix}_${typeName}" (SELECT * FROM "${fromTablePrefix}_${typeName}");`;
    if (!includeAudit) return table;
    const audit = `INSERT INTO "${toTablePrefix}_${typeName}Audit" (SELECT * FROM "${fromTablePrefix}_${typeName}Audit");`;
    return `${table}${"\n"}${audit}`;
  }

  public static dropTableSql(tablePrefix: string, schema: I_Schema | string): string {
    const { typeName, includeAudit } = this._processSchemaArg(schema);
    const table = `DROP TABLE IF EXISTS "${tablePrefix}_${typeName}" CASCADE;`;
    if (!includeAudit) return table;
    const audit = `DROP TABLE IF EXISTS "${tablePrefix}_${typeName}Audit" CASCADE;`;
    return `${table}${"\n"}${audit}`;
  }

  public static emptyTableSql(tablePrefix: string, schema: I_Schema | string): string {
    const { typeName, includeAudit } = this._processSchemaArg(schema);
    const table = `DELETE FROM "${tablePrefix}_${typeName}";`;
    if (!includeAudit) return table;
    const audit = `DELETE FROM "${tablePrefix}_${typeName}Audit";`;
    return `${table}${"\n"}${audit}`;
  }

  public static renameTableSql(oldTablePrefix: string, newTablePrefix: string, schema: I_Schema | string): string {
    const { typeName, includeAudit } = this._processSchemaArg(schema);
    const table = `ALTER TABLE IF EXISTS "${oldTablePrefix}_${typeName}" RENAME TO "${newTablePrefix}_${typeName}";`;
    if (!includeAudit) return table;
    const audit = `ALTER TABLE IF EXISTS "${oldTablePrefix}_${typeName}Audit" RENAME TO "${newTablePrefix}_${typeName}Audit";`;
    return `${table}${"\n"}${audit}`;
  }

  protected static _generateCastValue(field: FieldDefinition): string {
    let cast = "";
    if (Schema._castRequiredTypes.includes(field.type)) cast = `::${field.type}`;
    return `:${field.name}${cast}`;
  }

  public static generateAssignment(field: FieldDefinition): string {
    return `"${field.name}"=${Schema._generateCastValue(field)}`;
  }

  public static getDateTimeFields(schema: I_Schema): Array<string> {
    return schema.allProperties.filter((p) => p.type === "timestamp").map((p) => p.name);
  }
}
