export enum E_ManageFeatures {
  CUSTOM_FAVICON = "custom-favicon",
  DEVICES = "devices",
  MULTISITE_EMAIL_ADDRESS = "multisite-email-address",
  ONLINE_SIGNING_AUTO_SWITCH_ON = "online-signing-auto-switch-on",
  L2_L1_Login = "l2-l1-login",
}

export enum E_PatientFeatures {
  AVAILABILITY_API_V2 = "availability-api-v2",
  CAPTURE_INSURANCE_DETAILS = "capture-insurance-details",
  CHAT_BOT = "chat-bot",
  CONFIRM_APPOINTMENTS_AFTER_FORM_UPDATE = "confirm-appointments-after-form-update",
  EMAIL_SENDING_VIA_PMS = "email-sending-via-pms",
  EMERGENCY_CONTACT = "emergency-contact",
  PATIENT_GP_DETAILS = "patient-gp-details",
  RECAPTCHA = "recaptcha",
  SLOT_VARIANTS = "slot-variants",
  USE_CACHED_PATIENT_ACTIONS = "use-cached-patient-actions",
}

export type E_Features = E_ManageFeatures | E_PatientFeatures;

export enum E_SlotVariantFeature {
  FULL = "full",
  SINGLE = "single",
  MULTIPLE_SAME_DAY = "multiple-same-day",
  MULTIPLE_DIFFERENT_DAY = "multiple-different-day",
}
