import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Constants } from 'src/constants';
import { CommonService } from '../common.service';
import { DevService } from '../dev.service';
import { JWTService } from '../jwt.service';
import { NavigationService } from '../navigation.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard {
  constructor(
    private _commonService: CommonService,
    private _navigationService: NavigationService,
    private _jwtService: JWTService,
    private _devService: DevService
  ) {}

  // eslint-disable-next-line complexity
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    await this._commonService.initPracticeData();

    switch (state.url) {
      case Constants.ROUTE_FEATURES:
      case Constants.ROUTE_SUPPORT_TOOLS: {
        return this._devService.isUserImpersonating;
      }

      case Constants.ROUTE_PAYMENTS_TYPES: {
        const is_enabled = this._commonService.feat_Payments;

        if (!is_enabled) this._navigationService.navigate(Constants.ROUTE_BASE);
        return is_enabled;
      }

      case Constants.ROUTE_PAYMENTS_LINK_WITH_PAYMENT_PROVIDER: {
        const is_enabled = this._commonService.feat_Payments && this._commonService.isL4OrHigher;

        if (!is_enabled) this._navigationService.navigate(Constants.ROUTE_BASE);
        return is_enabled;
      }

      case Constants.ROUTE_PAYMENTS_SETTINGS: {
        const is_enabled = this._commonService.isL4OrHigher && this._commonService.feat_Payments;

        if (!is_enabled) this._navigationService.navigate(Constants.ROUTE_BASE);
        return is_enabled;
      }

      case Constants.ROUTE_CUSTOMISE_PRACTITIONERS: {
        const is_enabled = this._commonService.feat_CustomisePractitioners;

        if (!is_enabled) this._navigationService.navigate(Constants.ROUTE_BASE);
        return is_enabled;
      }

      case Constants.ROUTE_CUSTOMISE_BRANDS: {
        const is_enabled = this._commonService.feat_CustomiseBrands;

        if (!is_enabled) this._navigationService.navigate('customise');
        return is_enabled;
      }

      case Constants.ROUTE_CUSTOMISE_PORTAL: {
        const is_enabled = this._commonService.feat_CustomisePortal;

        if (!is_enabled) this._navigationService.navigate('customise');
        return is_enabled;
      }

      case Constants.ROUTE_CUSTOMISE_SETTINGS: {
        const is_enabled =
          this._commonService.isL4OrHigher &&
          (this._commonService.feat_CustomiseBrands || this._commonService.feat_CustomisePortal || this._commonService.feat_CustomisePractitioners);

        if (!is_enabled) this._navigationService.navigate('customise');
        return is_enabled;
      }

      case Constants.ROUTE_ONLINE_BOOKING_SETTINGS: {
        const is_enabled = this._commonService.feat_OnlineBookingSettings;

        if (!is_enabled) this._navigationService.navigate(Constants.ROUTE_BASE);
        return is_enabled;
      }

      case Constants.ROUTE_ONLINE_SIGNING_SETTINGS: {
        const is_enabled = this._commonService.isL4OrHigher && this._commonService.feat_OnlineSigning;

        if (!is_enabled) this._navigationService.navigate(Constants.ROUTE_BASE);
        return is_enabled;
      }

      case Constants.ROUTE_PATIENT_NOTIFICATION_SETTINGS: {
        const is_enabled = this._commonService.isL4OrHigher;

        if (!is_enabled) this._navigationService.navigate(Constants.ROUTE_BASE);
        return is_enabled;
      }

      case Constants.ROUTE_DEV_MODE_BULK_DATA: {
        const is_enabled = this._devService.isUserDev;

        if (!is_enabled) this._navigationService.navigate(Constants.ROUTE_BASE);
        return is_enabled;
      }

      case Constants.ROUTE_DEV_MODE_SUPPORT_TOOLS: {
        const { permission_level } = this._jwtService.getJWT();
        if (permission_level === 5) return true;

        this._navigationService.navigate(Constants.ROUTE_BASE);
        return false;
      }

      case Constants.ROUTE_DEVICES_SETTINGS:
      case Constants.ROUTE_DEVICES_PAIR: {
        const is_enabled = this._commonService.feat_Devices;

        if (!is_enabled) this._navigationService.navigate(Constants.ROUTE_BASE);
        return is_enabled;
      }

      default:
        return true;
    }
  }
}
