import { I_MissingPatientDetails } from "../patient-details/patient-details-base";
import { T_ValidAppointment } from "./routine-appointment-base";

export interface I_EstimateData {
  id: number;
  template_name: string;
}

export enum E_Restricted_Patient_Actions_Type {
  MEDICAL_HISTORY = "MEDICAL_HISTORY",
  PAYMENT = "PAYMENT",
  CHECK_IN = "CHECK_IN",
  ROUTINE_DENTAL_EXAM = "ROUTINE_DENTAL_EXAM",
  ROUTINE_HYGIENE_APPT = "ROUTINE_HYGIENE_APPT",
  ROUTINE_APPT = "ROUTINE_APPT",
}
export enum E_Patient_Actions_Type {
  CHECK_IN = "CHECK_IN",
  MEDICAL_HISTORY = "MEDICAL_HISTORY",
  NHS_PR = "NHS_PR",
  PAYMENT = "PAYMENT",
  TREATMENT_PLAN_ESTIMATE = "TREATMENT_PLAN_ESTIMATE",
  ROUTINE_DENTAL_EXAM = "ROUTINE_DENTAL_EXAM",
  ROUTINE_HYGIENE_APPT = "ROUTINE_HYGIENE_APPT",
  PATIENT_DETAILS = "PATIENT_DETAILS",
  ROUTINE_APPT = "ROUTINE_APPT", // not a real action returned from the BE, but used by Dentlally in action links
}

export class PatientActionBase {
  public practice_id: string;
  public patient_id: string;
  public appointment_id: string;
  public type: E_Patient_Actions_Type;
  public text: string;
  public data: PatientActionData = {};
  public task_reminder_sent_at: Date | null = null;

  constructor(practice_id: string, patient_id: string, appointment_id: string, type: E_Patient_Actions_Type, text: string) {
    this.practice_id = practice_id;
    this.patient_id = patient_id;
    this.appointment_id = appointment_id;
    this.type = type;
    this.text = text;
  }
}

export class PatientActionData {
  public question_count?: number;
  public routine_appt_data?: Array<T_ValidAppointment>;
  public estimates?: Array<I_EstimateData>;
  public patient_details_completed_percentage?: number;
  public patient_details_missing_data?: I_MissingPatientDetails;
}
